<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper ref="fieldWrapper" :field="field" :input-group="true">
      <!------------ START: Multiselect ------------>
      <v-autocomplete
        ref="select"
        v-model="$v.value.$model"
        :items="items"
        item-text="label"
        item-value="value"
        class="form-control"
        :class="[validationClass, isDisabled ? 'disabled' : '']"
        :clearable="field.clearable"
        clear-icon="fal fa-xmark icon-md"
        :menu-props="{ offsetY: true }"
        :no-data-text="$t('formHelper.noSelectOptions')"
        :placeholder="getSnippet(field.placeholder)"
        :multiple="true"
        :disabled="isDisabled"
        @input="onInput"
      />
      <!------------ END: Multiselect ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip
      v-if="isMounted"
      :target="$refs.fieldWrapper.$refs.inputGroup"
      :errors="validationErrors"
    />
    <!------------ END: Error messages tooltip ------------>
  </div>
</template>

<script>
import { base, select } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";

export default {
  components: { FieldWrapper, ErrorsTooltip },
  mixins: [base, select],
  props: {},
  data() {
    return {
      isMounted: false
    };
  },
  computed: {},
  mounted() {
    this.isMounted = true;
  },
  methods: {}
};
</script>
